var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-row',{staticClass:"justify-content-center"},[_c('h3',[_vm._v("Update plan information")]),_c('b-col',{attrs:{"md":"12"}},[_c('VeeObserver',{ref:"form",attrs:{"slim":""}},[_c('VeeProvider',{attrs:{"name":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Name"}},[_c('base-input',{staticClass:"input-group-alternative",attrs:{"placeholder":"Name","error":errors[0]},model:{value:(_vm.payload.name),callback:function ($$v) {_vm.$set(_vm.payload, "name", $$v)},expression:"payload.name"}})],1)]}}])}),_c('VeeProvider',{attrs:{"name":"value","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{directives:[{name:"money",rawName:"v-money",value:(_vm.money),expression:"money"}],staticClass:"input-group-alternative mb-3",attrs:{"placeholder":"Value"},model:{value:(_vm.payload.value),callback:function ($$v) {_vm.$set(_vm.payload, "value", $$v)},expression:"payload.value"}},[(errors.length)?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()])]}}])}),_c('VeeProvider',{attrs:{"name":"fees","rules":"required|min_value:0|max_value:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('base-input',{staticClass:"input-group-alternative",attrs:{"placeholder":"Fees(%)","addon-left-icon":"fa fa-percent","error":errors[0]},model:{value:(_vm.payload.fees),callback:function ($$v) {_vm.$set(_vm.payload, "fees", $$v)},expression:"payload.fees"}})]}}])}),_c('VeeProvider',{attrs:{"name":"payment cycle","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Payment cycle"}},[_c('v-select',{attrs:{"options":_vm.paymentCycleOptions,"reduce":function (ref) {
	var code = ref.code;

	return code;
}},model:{value:(_vm.payload.payment_cycle),callback:function ($$v) {_vm.$set(_vm.payload, "payment_cycle", $$v)},expression:"payload.payment_cycle"}})],1),(errors.length)?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()]}}])}),_c('div',{staticClass:"text-center"},[_c('base-button',{staticClass:"my-4",attrs:{"variant":"primary","type":"submit","loading":_vm.loading},on:{"click":function($event){_vm.isEdit ? _vm.update() : _vm.create()}}},[_c('i',{staticClass:"fa fa-save"}),_vm._v(" "+_vm._s(_vm.isEdit ? "Update" : "Create")+" ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
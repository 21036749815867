<template>
  <b-row class="justify-content-center">
    <h3>Update plan information</h3>
    <b-col md="12">
      <VeeObserver ref="form" slim>
        <VeeProvider name="name" rules="required" v-slot="{ errors }">
          <b-form-group label="Name">
            <base-input
              class="input-group-alternative"
              placeholder="Name"
              v-model="payload.name"
              :error="errors[0]"
            />
          </b-form-group>
        </VeeProvider>
        <VeeProvider name="value" rules="required" v-slot="{ errors }">
          <b-form-input
            v-money="money"
            class="input-group-alternative mb-3"
            v-model="payload.value"
            placeholder="Value"
          >
            <small class="text-danger" v-if="errors.length">
              {{ errors[0] }}
            </small>
          </b-form-input>
        </VeeProvider>
        <VeeProvider name="fees" rules="required|min_value:0|max_value:100" v-slot="{ errors }">
          <base-input
            class="input-group-alternative"
            placeholder="Fees(%)"
            addon-left-icon="fa fa-percent"
            v-model="payload.fees"
            :error="errors[0]"
          />
        </VeeProvider>
        <VeeProvider name="payment cycle" rules="required" v-slot="{ errors }">
          <b-form-group label="Payment cycle">
            <v-select
              :options="paymentCycleOptions"
              :reduce="({ code }) => code"
              v-model="payload.payment_cycle"
            ></v-select>
          </b-form-group>
          <small class="text-danger" v-if="errors.length">
            {{ errors[0] }}
          </small>
        </VeeProvider>
        <div class="text-center">
          <base-button
            variant="primary"
            type="submit"
            class="my-4"
            @click="isEdit ? update() : create()"
            :loading="loading"
          >
            <i class="fa fa-save"></i> {{ isEdit ? "Update" : "Create" }}
          </base-button>
        </div>
      </VeeObserver>
    </b-col>
  </b-row>
</template>
<script>
import PlansService from "features/settings/services/PlansService";
import { VueMaskDirective } from "v-mask";

export default {
  name: "FormPlans",
  data: () => ({
    loading: false,
    feesMode: true,
    payload: {
      name: "",
      fees: "",
      value: 0,
      payment_cycle: "",
    },
    money: {
      decimal: ",",
      thousands: ".",
      prefix: "$ ",
      suffix: "",
      precision: 2,
      masked: false,
    },
  }),
  computed: {
    isEdit() {
      return this.$route.name === "plans-edit";
    },
    paymentCycleOptions() {
      return [
        {
          label: "Yearly",
          code: "Y",
        },
        {
          label: "Monthly",
          code: "M",
        },
      ];
    },
  },
  directives: {
    mask: VueMaskDirective,
  },
  mounted() {
    if (this.isEdit) this.getData();
  },
  methods: {
    async getData() {
      try {
        this.loading = true;
        const { data } = await PlansService.show(this.$route.params.id);
        this.payload.name = data.name;
        this.payload.value = data.value.toLocaleString("en", {
          style: "currency",
          currency: "EN",
        });
        console.log(this.payload.value);
        this.payload.fees = data.fees;
        this.payload.payment_cycle = data.payment_cycle;
      } catch (e) {
        this.$swal({
          icon: "error",
          title: "Failed to get registration data",
        });
      } finally {
        this.loading = false;
      }
    },
    async create() {
      try {
        if (await this.$refs.form.validate()) {
          this.loading = true;
          const { name, fees, payment_cycle, value } = this.payload;
          const payload = {
            name,
            value,
            fees,
            payment_cycle,
          };
          await PlansService.create(payload);
          this.$swal({
            icon: "success",
            title: "Registration performed successfully",
          }).then(() => this.$router.push("/settings/plans"));
        }
      } catch (e) {
        this.$swal({
          icon: "error",
          title: "Trademark registration failed",
          text: "Check the data entered and try again",
        });
      } finally {
        this.loading = false;
      }
    },
    async update() {
      try {
        if (await this.$refs.form.validate()) {
          this.loading = true;
          const { name, fees, value, payment_cycle } = this.payload;
          const payload = { name, fees, value, payment_cycle };
          await PlansService.update(payload, this.$route.params.id);
          this.$swal({
            icon: "success",
            title: "Record updated successfully",
          }).then(() => this.$router.push("/settings/plans"));
        }
      } catch (e) {
        this.$swal({
          icon: "error",
          title: "Update failed",
          text: "Verify the given informations and try again",
        });
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
